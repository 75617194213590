// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples

//Animation imports
import { motion } from "framer-motion";

// Images
import app from "assets/images/services_logo/app.jpg";
import bags from "assets/images/services_logo/bags.jpg";
import barcode from "assets/images/services_logo/barcode.jpg";
import garbageCollect from "assets/images/services_logo/garbageCollect.jpg";
import process from "assets/images/services_logo/process.jpg";
import software from "assets/images/services_logo/software.jpg";
import training from "assets/images/services_logo/training.jpg";

import { Card, Typography } from "@mui/material";

const serviceNames = [
  { photo: garbageCollect, names: "Biomedical Waste Collection" },
  { photo: process, names: "Biomedical Waste Processing" },
  { photo: bags, names: "Supply of Waste Collection Bags" },
  { photo: barcode, names: "Barcode Stickers Sale For Bio-Medical Waste Management" },
  { photo: training, names: "Training Programs for BMW Handling and Segregation" },
  { photo: app, names: "Mobile App For Hospitals" },
  { photo: software, names: "Software For Bio-Medical Waste Management" },
];

function Information() {
  const AnimatedCard = motion(Grid);
  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };
  return (
    <MKBox component="section" py={6} mb={2}>
      <Grid container justifyContent="center" sx={{ mx: "auto" }}>
        {serviceNames.map((data, index) => {
          return (
            <Grid key={index} item xs={12} sm={3} md={3} lg={3}>
              <AnimatedCard
                variants={cardVariants}
                initial="hidden"
                animate="visible"
                transition={{ duration: 1 }}
                whileHover={{
                  scale: 1.05,
                  transition: { duration: 0.5 },
                }}
                whileTap={{ scale: 0.9 }}
                whileInView={{ opacity: 1 }}
              >
                <Card
                  sx={{
                    height: 250,
                    margin: 2,
                    padding: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img src={data.photo} alt="photos" height={150} />

                  <Typography key={index} variant="h6" textAlign="center" sx={{ marginTop: 1 }}>
                    {data.names}
                  </Typography>
                </Card>
              </AnimatedCard>
            </Grid>
          );
        })}
      </Grid>
    </MKBox>
  );
}

export default Information;

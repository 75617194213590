import React from "react";
import NavBar from "screens/Header";

//Animation imports
import { motion } from "framer-motion";
import { Divider, Grid } from "@mui/material";
import MKBox from "components/MKBox";
import history1 from "assets/images/history/history1.jpg";
import history2 from "assets/images/history/history2.jpg";
import history3 from "assets/images/history/history3.jpg";
import history4 from "assets/images/history/history4.jpg";
import history5 from "assets/images/history/history5.jpg";
import history6 from "assets/images/history/history6.jpg";
import history7 from "assets/images/history/history7.jpg";
import history8 from "assets/images/history/history8.jpg";
import history9 from "assets/images/history/history9.jpg";

import MKTypography from "components/MKTypography";
import Footer from "screens/Footer";

const histories = [
  { photo: history2 },
  { photo: history3 },
  { photo: history4 },
  { photo: history5 },
  { photo: history6 },
  { photo: history7 },
  { photo: history8 },
  { photo: history9 },
];

const History = () => {
  const AnimatedCard = motion(Grid);
  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };
  return (
    <>
      <NavBar />
      <AnimatedCard
        variants={cardVariants}
        initial="hidden"
        animate="visible"
        transition={{ duration: 1 }}
      >
        <MKBox
          minHeight="23vh"
          width="100%"
          sx={{
            display: "grid",
            alignItems: "flex-end",
          }}
        >
          <MKTypography textAlign="center" variant="h3" color="primary" sx={{ marginBottom: 3 }}>
            Our History...
          </MKTypography>
        </MKBox>
      </AnimatedCard>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        maxWidth="xl"
        rowSpacing={3}
        sx={{
          mx: "auto",

          display: { xs: "block", sm: "block", md: "block", lg: "block" },
        }}
      >
        <Grid container align="center" justifyContent="center" alignItems="center">
          <Grid item xs={12} md={6}>
            <AnimatedCard
              variants={cardVariants}
              initial={{ opacity: 0.6 }}
              whileTap={{ scale: 0.9 }}
              whileInView={{ opacity: 1 }}
            >
              <MKBox
                width="80%"
                height="400px"
                borderRadius="lg"
                mt={2}
                sx={{
                  backgroundImage: `url(${history1})`,
                  backgroundSize: "cover",
                  backgroundPosition: "top",
                  display: "grid",
                }}
              />
            </AnimatedCard>
          </Grid>
          <Grid item align="center" xs={12} md={6}>
            <MKTypography mt={2} mx={2} textAlign="justify" variant="body2">
              Surya central Treatment facility is founded and started on 1st January 2003 as a
              Propriotory Firm by Dr. Meghana Kore. Under the privileges guidance and support of her
              father Lt. Shrikant Ganpatrao Arwade. With technical guidance and know how of total
              BMW rules and Scientific Operations of Mrs. Manjeeri Chunekar channel partners of
              Donwitely Scientific Equipments Pvt Ltd. Uk. The project planning and technical know
              how given by these senior Industrialist helped so well to run this project
              successfully for last 20 years This is a proude moment to mention and Special thanks
              to Lt. Dr Patangrao kadam Saheb Udyog Mantri in 2002 – 2003 gave special permission
              for this business to Run In Maharashtra state Industrial Development corporation area
              Of Miraj MIDC region and special amendmend was done in the MIDC Bi-laws. Also Special
              Thanks to lt. Anandrao Mohite former Director MIDC and the IAS officers OF MIDC During
              2002 -2003 who helped and understood the importance of security of this business to
              run under shelter of MIDC Zone. We also give special thanks to the sangli City Doctors
              who understood the importance of this law and also trusted surya can do this job 100%
              authentically joined first to our Facility{" "}
            </MKTypography>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <Divider />
        </Grid>
        <AnimatedCard
          variants={cardVariants}
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.5 }}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            maxWidth="xl"
            sx={{ mx: "auto" }}
          >
            {histories.map((data, index) => {
              return (
                <Grid
                  key={index}
                  align="center"
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  lg={3}
                  sx={{ padding: 2 }}
                >
                  <AnimatedCard
                    variants={cardVariants}
                    initial={{ opacity: 0.6 }}
                    whileHover={{
                      scale: 1.05,
                      transition: { duration: 0.5 },
                    }}
                    whileTap={{ scale: 0.9 }}
                    whileInView={{ opacity: 1 }}
                  >
                    <MKBox
                      width="100%"
                      height="200px"
                      borderRadius="lg"
                      sx={{
                        backgroundImage: `url(${data.photo})`,
                        backgroundSize: "cover",
                        backgroundPosition: "top",
                        display: "grid",
                      }}
                    />
                  </AnimatedCard>
                </Grid>
              );
            })}
            <Grid
              item
              sm={6}
              md={6}
              lg={6}
              sx={{ display: { xs: "none", sm: "block", md: "block", lg: "block" } }}
            ></Grid>
          </Grid>
          <MKBox pt={6} px={1} mt={6}>
            <Footer />
          </MKBox>
        </AnimatedCard>
      </Grid>
    </>
  );
};

export default History;

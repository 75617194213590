import React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

//Animation imports
import { motion } from "framer-motion";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

//projects

//assets
import wasteProcessing from "assets/images/waste-processing.jpeg";
import wasteCollection from "assets/images/waste-collection.jpeg";
import consultancy from "assets/images/consultancy.jpeg";
import software from "assets/images/software.png";
import bags from "assets/images/bags.webp";
import Footer from "screens/Footer";
import { Divider } from "@mui/material";

const ServicesMobile = () => {
  const AnimatedCard = motion(Grid);
  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };
  return (
    <>
      <MKBox sx={{ marginY: 6 }}></MKBox>
      <AnimatedCard
        variants={cardVariants}
        initial="hidden"
        animate="visible"
        transition={{ duration: 0.5 }}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          maxWidth="xl"
          rowSpacing={3}
          sx={{ mx: "auto", px: 3 }}
        >
          <Grid container justifyContent="center" alignItems="center" spacing={2}>
            <Grid align="center" item xs={12} md={6}>
              <AnimatedCard
                variants={cardVariants}
                initial={{ opacity: 0.6 }}
                whileHover={{
                  scale: 1.1,
                  transition: { duration: 1 },
                }}
                whileTap={{ scale: 0.9 }}
                whileInView={{ opacity: 1 }}
              >
                <MKBox
                  width="80%"
                  height="300px"
                  borderRadius="lg"
                  mt={2}
                  sx={{
                    backgroundImage: `url(${wasteCollection})`,
                    backgroundSize: "cover",
                    backgroundPosition: "top",
                    display: "grid",
                  }}
                />
              </AnimatedCard>
            </Grid>

            <Grid item align="center" xs={12} md={6}>
              <MKTypography variant="h5">Boimedical Waste Collection</MKTypography>
              <MKTypography mt={2} textAlign="justify" variant="body2">
                Ensure safe and compliant transportation of bio-medical waste with our specialized
                vehicles and trained personnel. We adhere to strict guidelines to minimize
                environmental impact and protect public health.
              </MKTypography>
            </Grid>
          </Grid>

          <Grid item xs={12} md={12}>
            <Divider />
          </Grid>
          <AnimatedCard
            variants={cardVariants}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.5 }}
          >
            <Grid container justifyContent="center" alignItems="center" spacing={2}>
              <Grid item align="center" xs={12} md={6}>
                <AnimatedCard
                  variants={cardVariants}
                  initial={{ opacity: 0.6 }}
                  whileHover={{
                    scale: 1.1,
                    transition: { duration: 1 },
                  }}
                  whileTap={{ scale: 0.9 }}
                  whileInView={{ opacity: 1 }}
                >
                  {" "}
                  <MKBox
                    width="80%"
                    height="300px"
                    borderRadius="lg"
                    mt={2}
                    sx={{
                      backgroundImage: `url(${wasteProcessing})`,
                      backgroundSize: "cover",
                      backgroundPosition: "top",
                      display: "grid",
                    }}
                  />
                </AnimatedCard>
              </Grid>
              <Grid item align="center" xs={12} md={6}>
                <MKTypography variant="h5">Boimedical Waste Processing</MKTypography>
                <MKTypography mt={2} textAlign="justify" variant="body2">
                  Experience efficient and eco-friendly bio-medical waste processing with our
                  state-of-the-art facilities and advanced technologies. We ensure the safe and
                  compliant treatment of waste, reducing environmental impact and safeguarding
                  public health. Trust our expertise to handle your waste responsibly and in
                  accordance with regulatory standards.
                </MKTypography>
              </Grid>
            </Grid>
          </AnimatedCard>

          <Grid item xs={12} md={12}>
            <Divider />
          </Grid>
          <AnimatedCard
            variants={cardVariants}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.5 }}
          >
            <Grid container align="center" justifyContent="center" alignItems="center" spacing={2}>
              <Grid item xs={12} md={6}>
                <AnimatedCard
                  variants={cardVariants}
                  initial={{ opacity: 0.6 }}
                  whileHover={{
                    scale: 1.1,
                    transition: { duration: 1 },
                  }}
                  whileTap={{ scale: 0.9 }}
                  whileInView={{ opacity: 1 }}
                >
                  <MKBox
                    width="80%"
                    height="300px"
                    borderRadius="lg"
                    mt={2}
                    sx={{
                      backgroundImage: `url(${bags})`,
                      backgroundSize: "cover",
                      backgroundPosition: "top",
                      display: "grid",
                    }}
                  />
                </AnimatedCard>
              </Grid>
              <Grid item align="center" xs={12} md={6}>
                <MKTypography variant="h5">
                  Supply of Waste Collection Bags and Barcodes
                </MKTypography>
                <MKTypography mt={2} textAlign="justify" variant="body2">
                  Purchase high-quality, color-coded plastic bags designed for proper segregation
                  and disposal of bio-medical waste. Our bags meet industry standards and help
                  maintain a clean and organized waste management system. Streamline your waste
                  tracking process with our barcode stickers, designed specifically for bio-medical
                  waste management. Easily track waste from generation to disposal, ensuring
                  compliance and efficient waste handling.
                </MKTypography>
              </Grid>
            </Grid>
          </AnimatedCard>

          <Grid item xs={12} md={12}>
            <Divider />
          </Grid>
          <AnimatedCard
            variants={cardVariants}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.5 }}
          >
            <Grid container justifyContent="center" alignItems="center" spacing={2}>
              <Grid item align="center" xs={12} md={6}>
                <AnimatedCard
                  variants={cardVariants}
                  initial={{ opacity: 0.6 }}
                  whileHover={{
                    scale: 1.1,
                    transition: { duration: 1 },
                  }}
                  whileTap={{ scale: 0.9 }}
                  whileInView={{ opacity: 1 }}
                >
                  <MKBox
                    width="80%"
                    height="300px"
                    borderRadius="lg"
                    mt={2}
                    sx={{
                      backgroundImage: `url(${consultancy})`,
                      backgroundSize: "cover",
                      backgroundPosition: "top",
                      display: "grid",
                    }}
                  />
                </AnimatedCard>
              </Grid>
              <Grid item align="center" xs={12} md={6}>
                <MKTypography variant="h5">
                  Training Programs for BMW Handling and Segregation
                </MKTypography>
                <MKTypography mt={2} textAlign="justify" variant="body2">
                  Empower your staff with the knowledge and skills to handle bio-medical waste
                  safely and effectively. Our comprehensive training programs cover waste
                  segregation, handling, and disposal best practices, ensuring a safe and compliant
                  workplace.
                </MKTypography>
              </Grid>
            </Grid>
          </AnimatedCard>

          <Grid item xs={12} md={12}>
            <Divider />
          </Grid>
          <AnimatedCard
            variants={cardVariants}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.5 }}
          >
            <Grid container justifyContent="center" alignItems="center" spacing={2}>
              <Grid item align="center" xs={12} md={6}>
                <AnimatedCard
                  variants={cardVariants}
                  initial={{ opacity: 0.6 }}
                  whileHover={{
                    scale: 1.1,
                    transition: { duration: 1 },
                  }}
                  whileTap={{ scale: 0.9 }}
                  whileInView={{ opacity: 1 }}
                >
                  <MKBox
                    width="80%"
                    height="300px"
                    borderRadius="lg"
                    mt={2}
                    sx={{
                      backgroundImage: `url(${software})`,
                      backgroundSize: "cover",
                      backgroundPosition: "top",
                      display: "grid",
                    }}
                  />
                </AnimatedCard>
              </Grid>
              <Grid item align="center" xs={12} md={6}>
                <MKTypography variant="h5">
                  Software and mobile app for CBMWTDF & Hospitals for Bio-Medical Waste Management
                </MKTypography>
                <MKTypography mt={2} textAlign="justify" variant="body2">
                  A cutting-edge software solution is tailored for Common Bio-Medical Waste
                  Treatment Facilities (CBMWTDF) and hospitals to streamline your bio-medical waste
                  management process. The software enables efficient waste tracking, reporting, and
                  compliance monitoring, ensuring a seamless and well-organized waste management
                  system. Stay ahead with our innovative technology designed to meet the unique
                  needs of the healthcare industry.
                </MKTypography>
              </Grid>
            </Grid>
          </AnimatedCard>

          <Grid item xs={12} md={12}>
            <Divider />
          </Grid>
        </Grid>
      </AnimatedCard>

      <MKBox pt={6} px={1} mt={6}>
        <Footer />
      </MKBox>
    </>
  );
};

export default ServicesMobile;

import axios from "axios";
import endpoints from "./endpoints";
const API = axios.create({
  baseURL: `${endpoints.serverBaseUrl}/api`,
  headers: { ContentType: "application/json" },
  withCredentials: true,
});
export const axiosOpen = axios.create({
  baseURL: `${endpoints.serverBaseUrl}/api`,
});

export default API;

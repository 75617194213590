/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
// import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
// import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  IconButton,
  InputAdornment,
  OutlinedInput,
  FormControl,
  useTheme,
  InputLabel,
  Stack,
  // Switch,
  FormHelperText,
} from "@mui/material";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKButton from "components/MKButton";
import { LoadingButton } from "@mui/lab";
//react hook form for validation
import { useForm, Controller } from "react-hook-form";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUser } from "../../../store/userSlice";

//yup

import * as yup from "yup";

import { yupResolver } from "@hookform/resolvers/yup";
// Images
import bgImage from "assets/images/bgImage.avif";
import NavBar from "screens/Header";
import useAuthenticationService from "services/AuthonticationService";
const ValidationSchema = yup.object().shape({
  Username: yup.string().max(255).required("UserName is required"),
  Password: yup.string().max(255).required("Password is required"),
});

function SignInBasic() {
  const { login } = useAuthenticationService();
  // const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const theme = useTheme();
  const {
    control,
    formState: { errors },
    watch,
    handleSubmit,
  } = useForm({
    defaultValues: {
      Username: "",
      Password: "",
    },
    mode: "all",
    resolver: yupResolver(ValidationSchema),
  });
  console.log("errs", errors, watch());
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [disable, setDisable] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  // const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const onSumbit = async (data) => {
    setSubmitting(true);
    setDisable(true);
    login(data)
      .then((response) => {
        console.log(response.data);
        if (response.status === 200) {
          if (response.data.userId > 0) {
            setSubmitting(false);
            setDisable(false);
            localStorage.setItem("accessToken", response.data.token); //accessToken
            dispatch(setUser(response.data));
            navigate("/technical-details");
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <NavBar />
      <MKBox
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      />
      <MKBox width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
        <Grid container justifyContent="center" alignItems="center" position="absolute" top={"20%"}>
          <Grid item xs={11} sm={8} md={5} margin="auto">
            <Card>
              <Grid container>
                {/* <Grid xs={6}>
                  <MKBox height={"100%"}>
                    <img
                      src={`https://leverageedublog.s3.ap-south-1.amazonaws.com/blog/wp-content/uploads/2020/04/29203350/Environmental-Conservation.jpg`}
                      style={{ height: "100%", width: "100%" }}
                    />
                  </MKBox>
                </Grid> */}
                <Grid xs={12}>
                  {/* <Card> */}
                  <Stack direction="column" justifyContent="space-evenly" spacing={3}>
                    <MKTypography variant="h4" fontWeight="medium" color="dark" mt={3} ml={4}>
                      Sign in
                    </MKTypography>
                    {/* </MKBox> */}
                    <MKBox pb={3} px={3}>
                      <form onSubmit={handleSubmit(onSumbit)}>
                        <MKBox mb={5}>
                          <FormControl
                            fullWidth
                            error={errors.Username}
                            sx={{ ...theme.typography.customInput }}
                          >
                            <InputLabel htmlFor="outlined-adornment-email-login">
                              Username
                            </InputLabel>

                            <Controller
                              name="Username"
                              control={control}
                              render={({ field }) => {
                                return (
                                  <>
                                    <OutlinedInput
                                      {...field}
                                      id="outlined-adornment-email-login"
                                      // type="email"
                                      label="Username"
                                    />
                                    {errors.Username && (
                                      <FormHelperText
                                        error
                                        id="standard-weight-helper-text-email-login"
                                      >
                                        {errors.Username.message}
                                      </FormHelperText>
                                    )}
                                  </>
                                );
                              }}
                            />
                          </FormControl>
                        </MKBox>
                        <MKBox mb={5}>
                          <FormControl
                            fullWidth
                            error={Boolean(errors.Password)}
                            sx={{ ...theme.typography.customInput }}
                          >
                            <InputLabel htmlFor="outlined-adornment-password-login">
                              Password
                            </InputLabel>
                            <Controller
                              name="Password"
                              control={control}
                              render={({ field }) => {
                                return (
                                  <OutlinedInput
                                    {...field}
                                    label="Password"
                                    id="outlined-adornment-password-login"
                                    type={showPassword ? "text" : "password"}
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={handleClickShowPassword}
                                          onMouseDown={handleMouseDownPassword}
                                          edge="end"
                                          size="large"
                                        >
                                          {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                      </InputAdornment>
                                    }
                                  />
                                );
                              }}
                            />
                            {errors.Password && (
                              <FormHelperText error id="standard-weight-helper-text-password-login">
                                {errors.Password.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </MKBox>

                        <MKBox mt={6}>
                          <LoadingButton
                            disabled={disable}
                            variant="contained"
                            color="primary"
                            fullWidth
                            type="submit"
                            // startIcon={<HowToRegIcon />}
                            loading={submitting}
                            loadingPosition="center"
                          >
                            <MKTypography variant="h6" color="white">
                              sign in
                            </MKTypography>
                          </LoadingButton>
                          {/* <MKButton variant="gradient" color="primary" fullWidth type="submit">
                            sign in
                          </MKButton> */}
                        </MKBox>
                      </form>
                    </MKBox>
                  </Stack>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </MKBox>
    </>
  );
}

export default SignInBasic;

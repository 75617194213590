import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userId: 0,
  profileId: 0,
  userName: "",
  role: 0,
  roleDisplay: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      console.log("payload", action.payload);
      state.userId = action.payload.userId;
      state.profileId = action.payload.profileId;
      state.userName = action.payload.displayName;
      state.role = action.payload.role;
      state.roleDisplay = action.payload.roleDisplay;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUser } = userSlice.actions;
export const selectUser = (state) => state.user;

export default userSlice.reducer;

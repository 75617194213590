/* eslint-disable no-param-reassign */
/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router components
import { NavLink } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 React base styles
import breakpoints from "assets/theme/base/breakpoints";
import NavbarMobile from "./NavbarMobile";

//assets
import logo from "assets/images/logo.png";

import { useSelector } from "react-redux";

import "./header.css";
const NavBar = () => {
  const user = useSelector((state) => state.userReducer);
  const [mobileNavbar, setMobileNavbar] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  console.log("user", user);
  const openMobileNavbar = () => setMobileNavbar(!mobileNavbar);

  useEffect(() => {
    // A function that sets the display state for the DefaultNavbarMobile.
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
        setMobileNavbar(false);
      } else {
        setMobileView(false);
        setMobileNavbar(false);
      }
    }

    /** 
     The event listener that's calling the displayMobileNavbar function when 
     resizing the window.
    */
    window.addEventListener("resize", displayMobileNavbar);

    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);

  return (
    <Container
      sx={{
        position: "sticky",
        top: 0,
        zIndex: 10,
      }}
    >
      <MKBox
        py={1.5}
        px={{ xs: 2, sm: 1, lg: 1 }}
        width={"calc(100% - 0px)"}
        shadow={"md"}
        color={"white"}
        position={"absolute"}
        left={0}
        zIndex={3}
        sx={({ palette: { white }, functions: { rgba } }) => ({
          backgroundColor: rgba(white.main, 0.8),
          backdropFilter: `saturate(200%) blur(30px)`,
          borderRadius: 4,
        })}
      >
        <MKBox display="flex" justifyContent="space-between" alignItems="center">
          <MKBox
            display="flex"
            justifyContent="space-between"
            component={NavLink}
            to="/"
            lineHeight={1}
            py={0.75}
            pl={{ xs: 0, lg: 1 }}
            border="none"
          >
            <MKBox
              width="80px"
              height="50px"
              borderRadius="md"
              sx={{
                backgroundImage: `url(${logo})`,
                backgroundSize: "cover",
                backgroundPosition: "top",
              }}
            />
            <MKBox display="flex" flexDirection="column" justifyContent="center" ml={1}>
              <MKTypography variant="button" fontWeight="bold" fontSize={15} color={"dark"}>
                Surya Central Treatment
              </MKTypography>
              <MKTypography variant="button" fontWeight="bold" fontSize={15} color={"dark"}>
                Facility Pvt. Ltd.
              </MKTypography>
            </MKBox>
          </MKBox>
          <MKBox>
            <MKBox
              ml={{ md: 1, lg: 5 }}
              sx={{
                display: {
                  lg: "flex",
                  xs: "none",
                  alignItems: "center",
                  justifyContent: "flex-end",
                },
              }}
              mr={1}
            >
              <MKButton
                component="a"
                href={"https://portal.suryactfbmw.in/"}
                target="_blank"
                rel="noreferrer"
                variant={"gradient"}
                color={"primary"}
                size="small"
              >
                Portal
              </MKButton>
              <MKButton
                component="a"
                href={"https://app.sparkvts.in/"}
                target="_blank"
                rel="noreferrer"
                variant={"gradient"}
                color={"info"}
                size="small"
                sx={{ ml: 1 }}
              >
                GPS
              </MKButton>
            </MKBox>
            <MKBox display={{ xs: "none", lg: "flex" }} ml="auto" mr={2} mt={0.5}>
              <MKBox
                component={NavLink}
                to="/"
                lineHeight={1}
                py={0.75}
                pl={{ xs: 0, lg: 1 }}
                ml={2}
              >
                <MKTypography variant="button" fontWeight="bold" color="inherit">
                  Home
                </MKTypography>
              </MKBox>
              <MKBox
                component={NavLink}
                to="/about"
                lineHeight={1}
                py={0.75}
                pl={{ xs: 0, lg: 1 }}
                ml={2}
              >
                <MKTypography variant="button" fontWeight="bold" color="inherit">
                  About Us
                </MKTypography>
              </MKBox>
              <MKBox
                component={NavLink}
                to="/history"
                lineHeight={1}
                py={0.75}
                pl={{ xs: 0, lg: 1 }}
                ml={2}
              >
                <MKTypography variant="button" fontWeight="bold" color="inherit">
                  History
                </MKTypography>
              </MKBox>
              <MKBox
                component={NavLink}
                to="/services"
                lineHeight={1}
                py={0.75}
                pl={{ xs: 0, lg: 1 }}
                ml={2}
              >
                <MKTypography variant="button" fontWeight="bold" color="inherit">
                  Services / Products
                </MKTypography>
              </MKBox>
              <MKBox
                component={NavLink}
                to="/current-events"
                lineHeight={1}
                py={0.75}
                pl={{ xs: 0, lg: 1 }}
                ml={2}
              >
                <MKTypography variant="button" fontWeight="bold" color="inherit">
                  Current Events
                </MKTypography>
              </MKBox>
              <MKBox
                component={NavLink}
                to={user.userId > 0 ? "/technical-details" : "/login"}
                lineHeight={1}
                py={0.75}
                pl={{ xs: 0, lg: 1 }}
                ml={2}
              >
                <MKTypography variant="button" fontWeight="bold" color="inherit">
                  Technical Details
                </MKTypography>
              </MKBox>

              <MKBox
                component={NavLink}
                to="/career"
                lineHeight={1}
                py={0.75}
                pl={{ xs: 0, lg: 1 }}
                ml={2}
              >
                <MKTypography variant="button" fontWeight="bold" color="inherit">
                  Career
                </MKTypography>
              </MKBox>
              <MKBox
                component={NavLink}
                to="/contact"
                lineHeight={1}
                py={0.75}
                pl={{ xs: 0, lg: 1 }}
                ml={2}
              >
                <MKTypography variant="button" fontWeight="bold" color="inherit">
                  Contact Us
                </MKTypography>
              </MKBox>
            </MKBox>
          </MKBox>

          <MKBox
            display={{ xs: "inline-block", lg: "none" }}
            lineHeight={0}
            py={1.5}
            pl={1.5}
            color={"inherit"}
            sx={{ cursor: "pointer" }}
            onClick={openMobileNavbar}
          >
            <Icon fontSize="default">{mobileNavbar ? "close" : "menu"}</Icon>
          </MKBox>
        </MKBox>
        <MKBox bgColor={"transparent"} shadow={"none"} borderRadius="xl" px={0}>
          {mobileView && <NavbarMobile open={mobileNavbar} />}
        </MKBox>
      </MKBox>
    </Container>
  );
};

export default NavBar;

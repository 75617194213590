import React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Image
import NavBar from "screens/Header";
import Footer from "screens/Footer";
import { motion } from "framer-motion";

const Contact = () => {
  const AnimatedCard = motion(Grid);
  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };
  const cardVariantsLeft = {
    hidden: { opacity: 0, x: 50 },
    visible: { opacity: 1, x: 0 },
  };
  const cardVariantsRight = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0 },
  };
  return (
    <>
      <NavBar />
      <AnimatedCard
        variants={cardVariants}
        initial="hidden"
        animate="visible"
        transition={{ duration: 1 }}
      >
        <MKBox
          minHeight="23vh"
          width="100%"
          sx={{
            display: "grid",
            alignItems: "flex-end",
          }}
        >
          <MKTypography textAlign="center" variant="h3" color="primary" sx={{ marginBottom: 3 }}>
            Contact Us...
          </MKTypography>
        </MKBox>
      </AnimatedCard>
      <Grid container rowSpacing={5} maxWidth="xl" sx={{ mx: "auto", px: 3, pt: 1 }}>
        <Grid item xs={12} lg={6}>
          <AnimatedCard
            variants={cardVariantsLeft}
            initial="hidden"
            animate="visible"
            transition={{ duration: 1 }}
          >
            <MKBox
              bgColor="white"
              borderRadius="xl"
              shadow="lg"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              mx={3}
              p={3}
            >
              <MKTypography variant="h5" color="primary">
                Surya Central Treatment Facility Pvt. Ltd.
              </MKTypography>
              <MKTypography variant="h6" mt={2}>
                Address
              </MKTypography>
              <MKTypography variant="body2">
                383/3, Kore Lawns, Sahayog Nagar, Near Sphuri Chowk, Vishrambag Sangli.
              </MKTypography>
              <MKTypography variant="h6" mt={2}>
                Contact
              </MKTypography>
              <MKTypography variant="body2">+91 8411811005</MKTypography>
              <MKTypography variant="body2">contactsuryactf@gmail.com</MKTypography>
            </MKBox>
          </AnimatedCard>
        </Grid>
        <Grid item xs={12} sm={10} md={7} lg={6} xl={4} sx={{ mx: "auto" }}>
          <AnimatedCard
            variants={cardVariantsRight}
            initial="hidden"
            animate="visible"
            transition={{ duration: 1 }}
          >
            <MKBox
              bgColor="white"
              borderRadius="xl"
              shadow="lg"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              mt={{ xs: 3, sm: 3, md: 3 }}
            >
              <MKBox
                variant="gradient"
                bgColor="info"
                coloredShadow="info"
                borderRadius="lg"
                p={2}
                mx={6}
                mt={-3}
              >
                <MKTypography variant="h4" color="white">
                  Get in touch with us...
                </MKTypography>
              </MKBox>
              <MKBox p={3}>
                <MKTypography variant="body2" color="text" mb={3}>
                  For further questions, contact us using our contact form.
                </MKTypography>
                <MKBox width="100%" component="form" method="post" autoComplete="off">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        variant="standard"
                        label="Full Name"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        variant="standard"
                        label="Mobile No."
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MKInput
                        variant="standard"
                        label="What can we help you?"
                        placeholder="Describe your problem in max 250 characters"
                        InputLabelProps={{ shrink: true }}
                        multiline
                        fullWidth
                        rows={6}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                    <MKButton type="submit" variant="gradient" color="info">
                      Send Message
                    </MKButton>
                  </Grid>
                </MKBox>
              </MKBox>
            </MKBox>
          </AnimatedCard>
        </Grid>
      </Grid>
      <MKBox pt={6} px={1} mt={6}>
        <Footer />
      </MKBox>
    </>
  );
};

export default Contact;

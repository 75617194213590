import React from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material";

//Animation imports
import { motion } from "framer-motion";

// Material Kit 2 React components
import MKBox from "components/MKBox";

//projects
import NavBar from "screens/Header";

//assets
//import career from "assets/images/career.jpg";
import Footer from "screens/Footer";
import { Card, Typography } from "@mui/material";
import MKTypography from "components/MKTypography";

const opportunities = [
  { role: "Bio-Medical Engineer", desc: "Diploma Holder" },
  { role: "Route coordinator", desc: "Diploma in logistics management " },
  { role: "Senior Accountant", desc: "BCom/MCom (Tally 5 yrs experience)" },
  { role: "Marketing and sales executive", desc: "BCom (3 yrs experience) " },
  { role: "Back office data entry executive", desc: "BCom" },
  { role: "Registration Executive", desc: "BCom (5 yrs experience)" },
  { role: "Plant supervisor ", desc: "12th to BCom" },
  { role: "Labour supply agencies", desc: "-" },
  { role: "Drivers", desc: "With valid licence (5 yrs experience)" },
  {
    role: "Machine Operator",
    desc: "Incerinators, Autoclave, Shredder, Machines experienced operator ",
  },
  { role: "Trainer", desc: "Qualified for waste segregation" },
];

const Career = () => {
  const theme = useTheme();
  const AnimatedCard = motion(Grid);
  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };
  return (
    <>
      <NavBar />
      <AnimatedCard
        variants={cardVariants}
        initial="hidden"
        animate="visible"
        transition={{ duration: 1 }}
      >
        <MKBox
          minHeight="23vh"
          width="100%"
          sx={{
            display: "grid",
            alignItems: "flex-end",
          }}
        >
          <MKTypography textAlign="center" variant="h3" color="primary" sx={{ marginBottom: 3 }}>
            Open Opportunities...
          </MKTypography>
        </MKBox>
      </AnimatedCard>

      {/* <AnimatedCard
        variants={cardVariants}
        initial="hidden"
        animate="visible"
        transition={{ duration: 0.5 }}
      >
        <Grid sx={{ display: { xs: "none", sm: "block", md: "block", lg: "block" } }}>
          <MKBox
            minHeight="100px"
            width="100%"
            sx={{
              display: "grid",
              alignItems: "flex-end",
            }}
          >
            <img src={career} alt="career" />
          </MKBox>
        </Grid>
        <Grid sx={{ display: { xs: "block", sm: "none", md: "none", lg: "none" } }}>
          <MKBox
            minHeight="23vh"
            width="100%"
            sx={{
              display: "grid",
              alignItems: "flex-end",
            }}
          >
            <img src={career} alt="career" width="100%" />
          </MKBox>
        </Grid>
        <Typography align="center" variant="h2" color="primary" sx={{ marginY: 3 }}>
          Open Opportunities
        </Typography>
      </AnimatedCard> */}

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        maxWidth="xl"
        sx={{ mx: "auto", marginTop: 1 }}
      >
        {opportunities.map((data, index) => {
          return (
            <Grid key={index} item xs={12} sm={6} md={6} lg={6} sx={{ padding: 2 }}>
              <AnimatedCard
                variants={cardVariants}
                initial="hidden"
                animate="visible"
                transition={{ duration: 1, delay: index / 4 }}
                whileHover={{
                  scale: 1.05,
                  transition: { duration: 0.2 },
                }}
              >
                <Card
                  sx={{
                    borderLeft: 4,
                    borderColor: theme.palette.secondary.main,
                    borderRadius: "2px",
                    padding: 2,
                  }}
                >
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Typography variant="h5">{data.role}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Typography variant="body2">{data.desc}</Typography>
                    </Grid>
                  </Grid>
                </Card>
              </AnimatedCard>
            </Grid>
          );
        })}
        <Grid
          item
          sm={6}
          md={6}
          lg={6}
          sx={{ display: { xs: "none", sm: "block", md: "block", lg: "block" } }}
        ></Grid>
      </Grid>

      <MKBox pt={6} px={1} mt={6}>
        <Footer />
      </MKBox>
    </>
  );
};

export default Career;

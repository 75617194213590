import React from "react";

//mui
import { Card, Grid } from "@mui/material";

//project
import NavBar from "screens/Header";

//MK
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

//assets
//import about from "assets/images/about.jpeg";
import director from "assets/images/director.png";
import plant1 from "assets/images/plants/plant1.jpeg";
import plant2 from "assets/images/plants/plant2.jpeg";
import plant3 from "assets/images/plants/plant3.jpeg";
import plant4 from "assets/images/plants/plant4.jpeg";
import plant5 from "assets/images/plants/plant5.jpeg";
import plant6 from "assets/images/plants/plant6.jpeg";
import plant7 from "assets/images/plants/plant7.jpeg";
import plant10 from "assets/images/plants/plant10.jpeg";
import Footer from "screens/Footer";

//Animation imports
import { motion } from "framer-motion";

const plants = [
  { photo: plant1 },
  { photo: plant2 },
  { photo: plant3 },
  { photo: plant4 },
  { photo: plant5 },
  { photo: plant6 },
  { photo: plant7 },
  { photo: plant10 },
];

const About = () => {
  const facilityImageHight = "300px";
  const AnimatedCard = motion(Grid);
  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <>
      <NavBar />
      <AnimatedCard
        variants={cardVariants}
        initial="hidden"
        animate="visible"
        transition={{ duration: 1 }}
      >
        <MKBox
          minHeight="23vh"
          width="100%"
          sx={{
            display: "grid",
            alignItems: "flex-end",
          }}
        >
          <MKTypography textAlign="center" variant="h3" color="primary" sx={{ marginBottom: 3 }}>
            About Us...
          </MKTypography>
        </MKBox>
      </AnimatedCard>
      <Grid container maxWidth="xl" sx={{ mx: "auto" }}>
        <Grid
          item
          xs={12}
          lg={6}
          sx={{ mb: 5, display: "flex", justifyContent: "center", alignItems: "top" }}
        >
          <Card
            sx={{
              marginTop: 2,
              width: "90%",
              height: 470,
              backgroundImage: `url(${director})`,
              backgroundSize: "cover",
              backgroundPosition: "top",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <AnimatedCard
              variants={cardVariants}
              initial="hidden"
              animate="visible"
              transition={{ duration: 0.5 }}
            >
              <Card
                sx={{
                  backgroundColor: "white",
                  opacity: 0.7,
                  paddingY: 1,
                  paddingX: 2,
                  width: 230,
                  margin: 1,
                }}
              >
                <AnimatedCard
                  variants={cardVariants}
                  initial="hidden"
                  animate="visible"
                  transition={{ duration: 1, delay: 0.5 }}
                >
                  <MKTypography textAlign="left" variant="h4">
                    Our Director
                  </MKTypography>
                </AnimatedCard>
                <AnimatedCard
                  variants={cardVariants}
                  initial="hidden"
                  animate="visible"
                  transition={{ duration: 1, delay: 1 }}
                >
                  <MKTypography textAlign="left" variant="h4">
                    Dr. Meghna Kore
                  </MKTypography>
                </AnimatedCard>
              </Card>
            </AnimatedCard>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <MKTypography textAlign="justify" variant="body2">
            {`According to Biomedical Waste (Management and Handling) Rules, 1998 of India “Any waste which is generated during the diagnosis, treatment or immunisation for human beings or animals or in research activities pertaining thereto or in the production or testing.`}
          </MKTypography>
          <MKTypography mt={2} textAlign="justify" variant="body2">
            {`The Government of India (notification, 1998) specifies that Hospital Waste Management is a part of hospital hygiene and maintenance activities. Improper management of waste generated in health care facilities causes a direct health impact on the community, the health care workers and on the environment.`}
          </MKTypography>
          <MKTypography mt={2} textAlign="justify" variant="body2">
            {`Despite of strict rules and regulations, many used syringes manage to pass through to unethical and illegal re-packaging units. Such used syringes are observed to be responsible to uncontrollable spreading of HIV, Hepatitis A, B and other life costing diseases; and worth to note that this is just one side-effect of unplanned and unmanaged Bio Waste Management in urban and rural healthcare sector; Being granddaughter of a renowned social worker Shri Ganpatraoji Arcade, Mrs. Meghana Kore couldn’t merely be a silent spectator of such unethical practices in community. She strongly felt that someone should take this work in hand with social attitude; and then being and innovative business activist at core she decided to fill the empty space of that “someone” by her own unmatched presence. This gave birth to a new social-business venture, Surya Central Treatment Facility for Bio Medical Waste Management.`}
          </MKTypography>
        </Grid>
      </Grid>

      <Grid container rowSpacing={3} maxWidth="xl" sx={{ mx: "auto" }} mt={5}>
        <Grid item xs={12}>
          <MKTypography textAlign="center" variant="h3" color="primary">
            Our Facility
          </MKTypography>
        </Grid>
        {plants.map((data, index) => {
          return (
            <Grid key={index} item xs={12} md={6} lg={3}>
              <AnimatedCard
                variants={cardVariants}
                initial="hidden"
                animate="visible"
                transition={{ duration: 1, delay: index / 4 }}
                whileHover={{
                  scale: 1.05,
                  transition: { duration: 0.5 },
                }}
              >
                <MKBox
                  width="calc(100% - 2rem)"
                  height={facilityImageHight}
                  borderRadius="lg"
                  ml={2}
                  sx={{
                    backgroundImage: `url(${data.photo})`,
                    backgroundSize: "cover",
                    backgroundPosition: "top",
                    display: "grid",
                  }}
                />
              </AnimatedCard>
            </Grid>
          );
        })}

        {/* <Grid item xs={12} md={6} lg={3}>
          <MKBox
            width="calc(100% - 2rem)"
            height={facilityImageHight}
            borderRadius="lg"
            ml={2}
            sx={{
              backgroundImage: `url(${plant2})`,
              backgroundSize: "cover",
              backgroundPosition: "top",
              display: "grid",
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <MKBox
            width="calc(100% - 2rem)"
            height={facilityImageHight}
            borderRadius="lg"
            ml={2}
            sx={{
              backgroundImage: `url(${plant3})`,
              backgroundSize: "cover",
              backgroundPosition: "top",
              display: "grid",
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <MKBox
            width="calc(100% - 2rem)"
            height={facilityImageHight}
            borderRadius="lg"
            ml={2}
            sx={{
              backgroundImage: `url(${plant4})`,
              backgroundSize: "cover",
              backgroundPosition: "top",
              display: "grid",
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <MKBox
            width="calc(100% - 2rem)"
            height={facilityImageHight}
            borderRadius="lg"
            ml={2}
            sx={{
              backgroundImage: `url(${plant5})`,
              backgroundSize: "cover",
              backgroundPosition: "top",
              display: "grid",
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <MKBox
            width="calc(100% - 2rem)"
            height={facilityImageHight}
            borderRadius="lg"
            ml={2}
            sx={{
              backgroundImage: `url(${plant6})`,
              backgroundSize: "cover",
              backgroundPosition: "top",
              display: "grid",
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <MKBox
            width="calc(100% - 2rem)"
            height={facilityImageHight}
            borderRadius="lg"
            ml={2}
            sx={{
              backgroundImage: `url(${plant7})`,
              backgroundSize: "cover",
              backgroundPosition: "top",
              display: "grid",
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <MKBox
            width="calc(100% - 2rem)"
            height={facilityImageHight}
            borderRadius="lg"
            ml={2}
            sx={{
              backgroundImage: `url(${plant10})`,
              backgroundSize: "cover",
              backgroundPosition: "top",
              display: "grid",
            }}
          />
        </Grid> */}
      </Grid>
      <MKBox pt={6} px={1} mt={6}>
        <Footer />
      </MKBox>
    </>
  );
};

export default About;

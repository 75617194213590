import { Card, Divider, Grid } from "@mui/material";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import React from "react";

//assets
import logo from "assets/images/logo.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <Card
        sx={{
          p: 2,
          mx: { xs: 0, lg: 3 },
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Grid
          container
          mb={2}
          maxWidth="xl"
          sx={{ mx: "auto", display: { lg: "flex", xs: "none" } }}
        >
          <Grid item xs={4}>
            <MKTypography variant="h5" color="secondary">
              Surya Central Treatment
            </MKTypography>
            <MKTypography variant="h5" color="secondary">
              Facility Pvt. Ltd.
            </MKTypography>
            <MKBox
              width="130px"
              height="80px"
              borderRadius="md"
              sx={{
                backgroundImage: `url(${logo})`,
                backgroundSize: "cover",
                backgroundPosition: "top",
                mt: 3,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <MKTypography variant="h6">Address</MKTypography>
            <MKTypography variant="body2">
              383/3, Kore Lawns, Sahayog Nagar, Near Sphuri Chowk, Vishrambag Sangli.
            </MKTypography>
            <MKTypography variant="h6" mt={2}>
              Contact
            </MKTypography>
            <MKTypography variant="body2">+91 8411811005</MKTypography>
            <MKTypography variant="body2">contactsuryactf@gmail.com</MKTypography>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={2} sx={{ display: "flex", flexDirection: "column" }}>
            <MKBox component={Link} to="/" lineHeight={1} py={0.75} pl={{ xs: 0, lg: 1 }} ml={2}>
              <MKTypography variant="button" fontWeight="bold" color={"dark"}>
                Home
              </MKTypography>
            </MKBox>
            <MKBox
              component={Link}
              to="/about"
              lineHeight={1}
              py={0.75}
              pl={{ xs: 0, lg: 1 }}
              ml={2}
            >
              <MKTypography variant="button" fontWeight="bold" color={"dark"}>
                About
              </MKTypography>
            </MKBox>
            <MKBox
              component={Link}
              to="/services"
              lineHeight={1}
              py={0.75}
              pl={{ xs: 0, lg: 1 }}
              ml={2}
            >
              <MKTypography variant="button" fontWeight="bold" color={"dark"}>
                Services
              </MKTypography>
            </MKBox>
            <MKBox
              component={Link}
              to="/contact"
              lineHeight={1}
              py={0.75}
              pl={{ xs: 0, lg: 1 }}
              ml={2}
            >
              <MKTypography variant="button" fontWeight="bold" color={"dark"}>
                Contact
              </MKTypography>
            </MKBox>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
          >
            <MKTypography variant="h6">
              All Rights Reserved Surya Central Treatement Facility Pvt. Ltd.
            </MKTypography>
            <MKTypography
              variant="h6"
              component="a"
              href={"https://www.amrujglobals.com/"}
              target="_blank"
            >
              Developed & Maintained by Amruj Global Solutions, Sangli
            </MKTypography>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default Footer;

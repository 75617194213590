import { axiosOpen } from "api/api";
import { endpoints } from "../api";
import { handleResponse, handleError } from "./Response";

const useAuthenticationService = () => {
  const login = async (user) => {
    console.log("userAuth", user);

    try {
      const response = await axiosOpen.post(
        endpoints.api.auth.login + `?Username=${user?.Username}&Password=${user?.Password}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };

  return { login };
};

export default useAuthenticationService;

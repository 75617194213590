/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Collapse from "@mui/material/Collapse";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { Divider } from "@mui/material";
import MKButton from "components/MKButton";

function NavbarMobile({ open }) {
  return (
    <Collapse in={Boolean(open)} timeout="auto" unmountOnExit>
      <MKBox
        width="calc(100% + 1.625rem)"
        my={2}
        ml={2}
        sx={{ display: "flex", flexDirection: "column" }}
      >
        <MKBox component={Link} to="/" lineHeight={1} py={0.75} pl={{ xs: 0, lg: 1 }}>
          <MKTypography variant="button" fontWeight="bold" color={"dark"}>
            Home
          </MKTypography>
        </MKBox>
        <MKBox component={Link} to="/about" lineHeight={1} py={0.75} pl={{ xs: 0, lg: 1 }}>
          <MKTypography variant="button" fontWeight="bold" color={"dark"}>
            About
          </MKTypography>
        </MKBox>
        <MKBox component={Link} to="/history" lineHeight={1} py={0.75} pl={{ xs: 0, lg: 1 }}>
          <MKTypography variant="button" fontWeight="bold" color={"dark"}>
            History
          </MKTypography>
        </MKBox>
        <MKBox component={Link} to="/services" lineHeight={1} py={0.75} pl={{ xs: 0, lg: 1 }}>
          <MKTypography variant="button" fontWeight="bold" color={"dark"}>
            Services
          </MKTypography>
        </MKBox>
        <MKBox component={Link} to="/current-events" lineHeight={1} py={0.75} pl={{ xs: 0, lg: 1 }}>
          <MKTypography variant="button" fontWeight="bold" color={"dark"}>
            Current Events
          </MKTypography>
        </MKBox>
        <MKBox component={Link} to="/career" lineHeight={1} py={0.75} pl={{ xs: 0, lg: 1 }}>
          <MKTypography variant="button" fontWeight="bold" color={"dark"}>
            Career
          </MKTypography>
        </MKBox>
        <MKBox
          component={Link}
          to="/technical-details"
          lineHeight={1}
          py={0.75}
          pl={{ xs: 0, lg: 1 }}
        >
          <MKTypography variant="button" fontWeight="bold" color={"dark"}>
            Technical Details
          </MKTypography>
        </MKBox>
        <MKBox component={Link} to="/contact" lineHeight={1} py={0.75} pl={{ xs: 0, lg: 1 }}>
          <MKTypography variant="button" fontWeight="bold" color={"dark"}>
            Contact
          </MKTypography>
        </MKBox>
        <Divider />
        <MKBox sx={{ display: "flex", flexDirection: "row" }}>
          <MKBox ml={{ md: 1, lg: 5 }} sx={{ display: { xs: "flex", lg: "none" } }} mr={2}>
            <MKButton
              component="a"
              href={"https://portal.suryactfbmw.in/"}
              target="_blank"
              rel="noreferrer"
              variant={"gradient"}
              color={"primary"}
              size="small"
            >
              Portal
            </MKButton>
          </MKBox>
          <MKBox ml={{ md: 1, lg: 1 }} sx={{ display: { xs: "flex", lg: "none" } }} mr={2}>
            <MKButton
              component="a"
              href={"https://app.sparkvts.in/"}
              target="_blank"
              rel="noreferrer"
              variant={"gradient"}
              color={"info"}
              size="small"
            >
              GPS
            </MKButton>
          </MKBox>
        </MKBox>
      </MKBox>
    </Collapse>
  );
}

// Typechecking props for the DefaultNavbarMobile
NavbarMobile.propTypes = {
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
};

export default NavbarMobile;

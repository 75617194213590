//project imports
import { setUser } from "../store/userSlice";

import { API, endpoints } from "../api";
//Third party imports
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";
// import { selectUser } from "../store/userSlice";
import { axiosOpen } from "api/api";

const useAuthAxios = () => {
  const { accessToken } = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = async () => {
    localStorage.removeItem("accessToken");
    dispatch(
      setUser({
        userId: 0,
        profileId: 0,
        userName: "",
        role: 0,
        displayRole: "",
      })
    );
    navigate("/login");
  };

  useEffect(() => {
    API.interceptors.request.use(async (req) => {
      let accessToken = localStorage.getItem("accessToken");

      req.headers["Authorization"] = `Bearer ${accessToken}`;
      const decodedToken = jwt_decode(accessToken);
      const isExpired = dayjs.unix(decodedToken.exp).diff(dayjs()) < 1;
      if (!isExpired) return req;

      let request = {
        accessToken: accessToken,
      };

      try {
        const response = await axiosOpen.post(endpoints.api.auth.refresh, request);
        localStorage.setItem("accessToken", response.data.accessToken);
        req.headers["Authorization"] = `Bearer ${response.data.accessToken}`;
        return req;
      } catch (error) {
        console.log("useAuthAxios", error);
        alert("Your session exired, login again.");
        handleLogout();
        return;
      }
    });

    // return () => {
    //   axiosAuth.interceptors.request.eject(requestIntercept);
    // };
  }, [accessToken]); //add auth
  return API;
};

export default useAuthAxios;

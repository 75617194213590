import React from "react";
// @mui material components
import { Grid } from "@mui/material";

// Material Kit 2 React components
import MKBox from "components/MKBox";

//projects
import NavBar from "screens/Header";
import event1 from "assets/images/events/event1.png";
import event2 from "assets/images/events/event2.png";
import event3 from "assets/images/events/event3.png";
import event4 from "assets/images/events/event4.jpg";
import event5 from "assets/images/events/event5.jpg";
import event6 from "assets/images/events/event6.jpg";
import event7 from "assets/images/events/event7.jpg";
import event8 from "assets/images/events/event8.jpg";
import event9 from "assets/images/events/event9.jpeg";
import Footer from "screens/Footer";

//Animation imports
import { motion } from "framer-motion";
import MKTypography from "components/MKTypography";

const images = [
  { photo: event1 },
  { photo: event2 },
  { photo: event3 },
  { photo: event4 },
  { photo: event5 },
  { photo: event6 },
  { photo: event7 },
  { photo: event8 },
  { photo: event9 },
];

const CurrentEvents = () => {
  const AnimatedCard = motion(Grid);
  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };
  return (
    <div>
      <NavBar />
      <AnimatedCard
        variants={cardVariants}
        initial="hidden"
        animate="visible"
        transition={{ duration: 1 }}
      >
        <MKBox
          minHeight="23vh"
          width="100%"
          sx={{
            display: "grid",
            alignItems: "flex-end",
          }}
        >
          <MKTypography textAlign="center" variant="h3" color="primary" sx={{ marginBottom: 3 }}>
            Current Events...
          </MKTypography>
        </MKBox>
      </AnimatedCard>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        maxWidth="xl"
        sx={{ mx: "auto" }}
      >
        {images.map((data, index) => {
          return (
            <Grid key={index} align="center" item xs={12} sm={4} md={4} lg={4} sx={{ padding: 2 }}>
              <AnimatedCard
                variants={cardVariants}
                initial="hidden"
                animate="visible"
                transition={{ duration: 1, delay: index / 4 }}
                whileHover={{
                  scale: 1.05,
                  transition: { duration: 0.5 },
                }}
              >
                <MKBox
                  width="100%"
                  height="300px"
                  borderRadius="lg"
                  sx={{
                    backgroundImage: `url(${data.photo})`,
                    backgroundSize: "cover",
                    backgroundPosition: "top",
                    display: "grid",
                  }}
                />
              </AnimatedCard>
            </Grid>
          );
        })}
        <Grid
          item
          sm={6}
          md={6}
          lg={6}
          sx={{ display: { xs: "none", sm: "block", md: "block", lg: "block" } }}
        ></Grid>
      </Grid>
      <MKBox pt={6} px={1} mt={6}>
        <Footer />
      </MKBox>
    </div>
  );
};

export default CurrentEvents;

import React from "react";
import NavBar from "screens/Header";
import { Card, Grid, IconButton, Stack, Typography } from "@mui/material";
import DownloadIcon from "@mui/icons-material/FileDownloadOutlined";
import LogOutIcon from "@mui/icons-material/LogoutRounded";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import MKBox from "components/MKBox";
import Footer from "screens/Footer";
import usePdfDowloadService from "services/usePdfDownloadService";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUser } from "../../store/userSlice";
import { motion } from "framer-motion";
import { saveAs } from "file-saver";
// import { saveAs } from "file-saver";
// import { toByteArray } from "base64-js";
// import { LoadingButton } from "@mui/lab";
const TechnicalDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [disable, setDisabled] = useState(false);
  // const [submitting, setSubmittinbg] = useState(false);
  const user = useSelector((state) => state.userReducer);
  const { downloadPdf } = usePdfDowloadService();
  const AnimatedCard = motion(Grid);
  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };
  const handledownloadFile = async (id, name) => {
    // setDisabled(true);
    // setSubmittinbg(true);
    try {
      const result = await downloadPdf(id);
      const pdfData = result.data;
      console.log("pdf", pdfData);
      const blob = base64toBlob(pdfData);
      saveAs(blob, `${name}.pdf`);
    } catch (err) {
      console.log(err);
    }
  };
  function base64toBlob(base64Data) {
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: "application/pdf" });
  }

  const handleLogout = () => {
    console.log("logout");
    localStorage.removeItem("accessToken");
    dispatch(
      setUser({
        userId: 0,
        profileId: 0,
        userName: "",
        role: 0,
        displayRole: "",
      })
    );
    navigate("/login");
  };

  return (
    <>
      <NavBar />
      <AnimatedCard
        variants={cardVariants}
        initial="hidden"
        animate="visible"
        transition={{ duration: 1 }}
      >
        <MKBox
          minHeight="23vh"
          width="100%"
          sx={{
            display: "grid",
            alignItems: "flex-end",
          }}
        >
          <MKTypography textAlign="center" variant="h3" color="primary" sx={{ marginBottom: 3 }}>
            Technical Details...
          </MKTypography>
        </MKBox>
      </AnimatedCard>

      <Grid container maxWidth="xl" rowSpacing={2} sx={{ mx: "auto", px: 3 }}>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <MKTypography textAlign="left" variant="h6">
              Logged In User: {user.userName}
            </MKTypography>

            <MKButton
              variant="standard"
              color="primary"
              size="small"
              sx={{ borderRadius: 10, opacity: 0.8 }}
              onClick={handleLogout}
            >
              <IconButton size="small">
                <LogOutIcon fontSize="small" />
              </IconButton>
              <MKTypography variant="h6">Sign Out</MKTypography>
            </MKButton>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ padding: 2 }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography>1. HCFs Rate chart for the year 2023 - 24</Typography>
              <MKButton
                onClick={() => {
                  handledownloadFile(1, "HCFs Rate chart for the year 2023 - 24");
                }}
                variant={"gradient"}
                color="primary"
                size="small"
              >
                <IconButton size="medium" color="white">
                  <DownloadIcon fontSize="large" />
                </IconButton>
                <MKTypography variant="h6" color="white">
                  Download
                </MKTypography>
                {/* <LoadingButton
                  color="white"
                  disabled={disable}
                  startIcon={
                    <IconButton size="medium" color="white">
                      <DownloadIcon fontSize="large" />
                    </IconButton>
                    <MKTypography variant="h6" color="white">
                    Download
                  </MKTypography>
                  }
                  loading={submitting}
                  loadingPosition="start"
                >
                  
                </LoadingButton> */}
              </MKButton>
            </Stack>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ padding: 2 }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography>2. MPCB Annual Report 2021</Typography>
              <MKButton
                onClick={() => {
                  handledownloadFile(2, "MPCB Annual Report 2021");
                }}
                variant={"gradient"}
                color="primary"
                size="small"
              >
                <IconButton size="medium" color="white">
                  <DownloadIcon fontSize="large" />
                </IconButton>
                <MKTypography variant="h6" color="white">
                  Download
                </MKTypography>
              </MKButton>
            </Stack>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ padding: 2 }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography>3. MPCB Annual Report 2022</Typography>
              <MKButton
                onClick={() => {
                  handledownloadFile(3, "MPCB Annual Report 2022");
                }}
                variant={"gradient"}
                color="primary"
                size="small"
              >
                <IconButton size="medium" color="white">
                  <DownloadIcon fontSize="large" />
                </IconButton>
                <MKTypography variant="h6" color="white">
                  Download
                </MKTypography>
              </MKButton>
            </Stack>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ padding: 2 }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography>4. MPCB Certificate for Surya CTF Pvt Ltd</Typography>
              <MKButton
                onClick={() => {
                  handledownloadFile(4, "MPCB Certificate for Surya CTF Pvt Ltd");
                }}
                variant={"gradient"}
                color="primary"
                size="small"
              >
                <IconButton size="medium" color="white">
                  <DownloadIcon fontSize="large" />
                </IconButton>
                <MKTypography variant="h6" color="white">
                  Download
                </MKTypography>
              </MKButton>
            </Stack>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ padding: 2 }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography>5. MPCB Consent to Establishment</Typography>
              <MKButton
                onClick={() => {
                  handledownloadFile(5, "MPCB Consent to Establishment");
                }}
                variant={"gradient"}
                color="primary"
                size="small"
              >
                <IconButton size="medium" color="white">
                  <DownloadIcon fontSize="large" />
                </IconButton>
                <MKTypography variant="h6" color="white">
                  Download
                </MKTypography>
              </MKButton>
            </Stack>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ padding: 2 }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography>6. MPCB License for the period of 2020 to 2022</Typography>
              <MKButton
                onClick={() => {
                  handledownloadFile(6, "MPCB License for the period of 2020 to 2022");
                }}
                variant={"gradient"}
                color="primary"
                size="small"
              >
                <IconButton size="medium" color="white">
                  <DownloadIcon fontSize="large" />
                </IconButton>
                <MKTypography variant="h6" color="white">
                  Download
                </MKTypography>
              </MKButton>
            </Stack>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ padding: 2 }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography>7. MPCB License for the period of 2022 to 2027</Typography>
              <MKButton
                onClick={() => {
                  handledownloadFile(7, "MPCB License for the period of 2022 to 2027");
                }}
                variant={"gradient"}
                color="primary"
                size="small"
              >
                <IconButton size="medium" color="white">
                  <DownloadIcon fontSize="large" />
                </IconButton>
                <MKTypography variant="h6" color="white">
                  Download
                </MKTypography>
              </MKButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
      <MKBox pt={6} px={1} mt={6}>
        <Footer />
      </MKBox>
    </>
  );
};

export default TechnicalDetails;

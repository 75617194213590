/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 React themes
import theme from "assets/theme";
//import Presentation from "layouts/pages/presentation";

// Material Kit 2 React routes
import HomePage from "screens";
import About from "screens/About";
import Services from "screens/Services";
import Contact from "screens/Contact";
import Career from "screens/Career";
import SignInPage from "layouts/pages/authentication/sign-in";
import CurrentEvents from "screens/CurrentEvents";
import History from "screens/History";

import TechnicalDetails from "screens/TechnicalDetails";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const loggedUser = useSelector((state) => state.userReducer);
  console.log("protectedroute", loggedUser);
  const accessToken = localStorage.getItem("accessToken");
  useEffect(() => {
    if (!loggedUser?.userId > 0 || !accessToken) {
      navigate("/login");
    }
  }, []);
  return loggedUser?.userId > 0 || accessToken ? children : navigate("/login");
};
export default function App() {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        <Route
          path="/technical-details"
          element={
            <ProtectedRoute>
              <TechnicalDetails />
            </ProtectedRoute>
          }
        />
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<About />} />
        <Route path="/history" element={<History />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/current-events" element={<CurrentEvents />} />
        <Route path="/career" element={<Career />} />
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="/login" element={<SignInPage />} />
      </Routes>
    </ThemeProvider>
  );
}

import React from "react";

// @mui material components
//import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

//video
//import bgVideo from "../assets/images/homepagebg.mp4";

// Images
import bgImage from "assets/images/home-banner.png";
import event1 from "assets/images/events/event1.png";
import event2 from "assets/images/events/event2.png";
import event3 from "assets/images/events/event3.png";
import event4 from "assets/images/events/event4.jpg";
import event5 from "assets/images/events/event5.jpg";
import event6 from "assets/images/events/event6.jpg";
import event7 from "assets/images/events/event7.jpg";
import event8 from "assets/images/events/event8.jpg";
import event9 from "assets/images/events/event9.jpeg";

import NavBar from "./Header";
import Counters from "./Components/Counters";
import Information from "./Components/Information";
import { Divider } from "@mui/material";

//third party
import SimpleImageSlider from "react-simple-image-slider";
import Footer from "./Footer";
import { motion } from "framer-motion";

const HomePage = () => {
  const AnimatedCard = motion(Grid);
  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };
  const images = [
    { url: event1 },
    { url: event2 },
    { url: event3 },
    { url: event4 },
    { url: event5 },
    { url: event6 },
    { url: event7 },
    { url: event8 },
    { url: event9 },
  ];

  return (
    <>
      <NavBar />
      {/* Banner */}
      {/* <Grid
        sx={{
          position: "relative",
          width: "100%",
          height: "85vh",
          overflow: "hidden",
          "& video": {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            minWidth: "100%",
            minHeight: "100%",
            width: "auto",
            height: "auto",
            zIndex: -1,
          },
          display: { xs: "none", sm: "block", md: "block", lg: "block" },
        }}
      >
        <video src={bgVideo} autoPlay loop muted />;
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto" my={28}>
            <AnimatedCard
              variants={cardVariants}
              initial="hidden"
              animate="visible"
              transition={{ duration: 1 }}
            >
              <MKTypography
                variant="h1"
                color="white"
                mb={4}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
                textAlign="center"
              >
                Surya Central Treatment Facility Pvt. Ltd.
              </MKTypography>
            </AnimatedCard>

            <AnimatedCard
              variants={cardVariants}
              initial="hidden"
              animate="visible"
              transition={{ duration: 1, delay: 0.5 }}
            >
              <MKTypography variant="h4" color="white" mb={1} textAlign="center">
                Your Trusted Partner For Bio-Medical Waste Management
              </MKTypography>
            </AnimatedCard>
          </Grid>
        </Container>
      </Grid> */}
      <Grid
        minHeight="90vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba } }) =>
            `${linearGradient(rgba("black", 0.25), rgba("black", 0.65))}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          //display: { xs: "block", sm: "none", md: "none", lg: "none" },
          placeItems: "center",
        }}
      >
        <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
          <AnimatedCard
            variants={cardVariants}
            initial="hidden"
            animate="visible"
            transition={{ duration: 1 }}
          >
            <MKTypography variant="h2" color="white" mb={3} mx={2} mt={25} textAlign="center">
              Surya Central Treatment Facility Pvt. Ltd.
            </MKTypography>
          </AnimatedCard>

          <AnimatedCard
            variants={cardVariants}
            initial="hidden"
            animate="visible"
            transition={{ duration: 1, delay: 0.5 }}
          >
            <MKTypography variant="h5" color="white" mx={4} textAlign="center">
              Your Trusted Partner For Bio-Medical Waste Management
            </MKTypography>
          </AnimatedCard>
        </Grid>
      </Grid>

      <Card
        sx={{
          px: 2,
          mx: { xs: 0, lg: 6 },
          mt: -11,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Counters />
        <Divider />
        <Grid container alignItems="center" my={3} maxWidth="lg" sx={{ mx: "auto" }}>
          <Grid item xs={12}>
            <MKTypography textAlign="center" variant="h3" color="primary" sx={{ mb: 4 }}>
              Project For Community Welfare
            </MKTypography>
          </Grid>
          <Grid item xs={12}>
            <MKTypography variant="body2" textAlign="center">
              {`Mrs. Meghana Kore never intended to run business of Surya Central Treatment Facility for profit. 
              Although the venture is executed with unparalleled level of professionalism, the foundation is a 
              thought of social work for community welfare and set an example of a commercial project which is 
              planned and executed with strict Social attitude.`}
            </MKTypography>
            <MKTypography variant="body2" textAlign="center">
              {`The project comes under Solid Waste Management and Hospital Waste Management. 
              Health care waste is heterogeneous mixture, which is very difficult to manage as such, 
              But the problem can be simplified and its dimension reduced considerably if a proper management 
              system is planned. Safe disposal of biomedical waste is now a legal requirement in India.
              The Biomedical Waste Management & Handling. Rules, 1998 came into force on 1998. 
              Surya Central Treatment Facility strictly follows all the rules and regulations prescribed 
              under this act. Under the leadership of Mrs. Meghana Kore, Surya Central Treatment Facility, 
              is working in Sangli over last 13 years and continuing!`}
            </MKTypography>
          </Grid>
        </Grid>

        <Divider />

        <Grid container alignItems="center" mt={1}>
          <Grid item xs={12}>
            <MKTypography textAlign="center" variant="h3" color="primary">
              Our Services
            </MKTypography>
          </Grid>
        </Grid>
        <Information />
        <Divider />

        <MKTypography textAlign="center" variant="h3" color="primary">
          Updates
        </MKTypography>
        <MKBox sx={{ display: "flex", justifyContent: "center" }}>
          <SimpleImageSlider
            style={{ marginTop: "50px", marginRight: 30 }}
            width={"90%"}
            height={500}
            images={images}
            showBullets={true}
            showNavs={true}
            autoPlay={true}
          />
        </MKBox>
        <br />
        <br />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <Footer />
      </MKBox>
    </>
  );
};

export default HomePage;

import useAuthAxios from "hooks/useAuthAxios";
import { handleError, handleResponse } from "./Response";

const usePdfDowloadService = () => {
  const API = useAuthAxios();
  const downloadPdf = async (id) => {
    try {
      const response = await API.get(`/TDFiles/DownloadFile?id=${id}`);
      return handleResponse(response);
    } catch (err) {
      return handleError(err);
    }
  };
  return { downloadPdf };
};
export default usePdfDowloadService;
